import React from "react"
import profile from "./profile.module.scss"

const Profile = () => (
  <div className={profile.profile}>
    <h1>Hi! I'm Tom Hoogeveen</h1>
    <img
      src={"./profile_shot.jpg"}
      className={profile.profileImage}
      alt="Logo"
    />
    <div className={profile.whoAmI}>
      <h2>Who am I?</h2>
      <p>I'm a Frontend Developer / Geek / Tinkerer</p>
    </div>
    <div className={profile.contact}>
      
      <a
        className={profile.contactLink}
        target="_blank" rel="noreferrer"
        href="https://www.linkedin.com/in/tom-hoogeveen-9a380165/"
      >
        <img
          src={"./icons/contact/linkedIn.ico"}
          alt="LinkedIn"
        />
      </a>

      <a
        className={profile.contactLink}
        target="_blank" rel="noreferrer"
        href="https://play.google.com/store/apps/developer?id=Tom+Hoogeveen&hl=nl&gl=US"
      >
        <img
          src={"./icons/contact/google_play_developer.png"}
          alt="Google play developer"
        />
      </a>

      
      <a
        className={profile.contactLink}
        target="_blank" rel="noreferrer"
        href="https://www.thingiverse.com/tomhoogeveen/designs"
      >
        <img
          src={"./icons/contact/thingiverse.png"}
          alt="thingiverse"
        />
      </a>
    </div>
  </div>
)
export default Profile
