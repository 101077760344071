import React from "react"
import '../styles/styles.scss';

import Profile from '../components/profile/profile';
import Layout from "../components/layout/layout";

export default function Home() {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
}